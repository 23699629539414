// This file was autogenerated. Please do not change.
export interface ICreateBankRequisitesRequestData {
    alias: string;
    beneficiaryAddress: string;
    beneficiaryBankAddress?: string;
    beneficiaryBankCity?: string;
    beneficiaryBankCountry?: string;
    beneficiaryBankCountryCode?: string;
    beneficiaryBankName?: string;
    beneficiaryBankPostalCode?: string;
    beneficiaryCity: string;
    beneficiaryCountry: string;
    beneficiaryCountryCode: string;
    beneficiaryIban: string;
    beneficiaryName: string;
    beneficiaryPostalCode?: string;
    beneficiarySwift: string;
}

export default class CreateBankRequisitesRequestData {
    readonly _alias: string;

    /** Description: Alias for requisites */
    get alias(): string {
        return this._alias;
    }

    readonly _beneficiaryAddress: string;

    /** Description: Beneficiary address */
    get beneficiaryAddress(): string {
        return this._beneficiaryAddress;
    }

    readonly _beneficiaryBankAddress: string | undefined;

    /** Description: Beneficiary bank address */
    get beneficiaryBankAddress(): string | undefined {
        return this._beneficiaryBankAddress;
    }

    readonly _beneficiaryBankCity: string | undefined;

    /** Description: Beneficiary bank city */
    get beneficiaryBankCity(): string | undefined {
        return this._beneficiaryBankCity;
    }

    readonly _beneficiaryBankCountry: string | undefined;

    /** Description: Beneficiary bank country */
    get beneficiaryBankCountry(): string | undefined {
        return this._beneficiaryBankCountry;
    }

    readonly _beneficiaryBankCountryCode: string | undefined;

    /** Description: Beneficiary bank country code in aplha2 format */
    get beneficiaryBankCountryCode(): string | undefined {
        return this._beneficiaryBankCountryCode;
    }

    readonly _beneficiaryBankName: string | undefined;

    /** Description: Beneficiary bank Name */
    get beneficiaryBankName(): string | undefined {
        return this._beneficiaryBankName;
    }

    readonly _beneficiaryBankPostalCode: string | undefined;

    /** Description: Beneficiary bank postal code */
    get beneficiaryBankPostalCode(): string | undefined {
        return this._beneficiaryBankPostalCode;
    }

    readonly _beneficiaryCity: string;

    /** Description: Beneficiary city */
    get beneficiaryCity(): string {
        return this._beneficiaryCity;
    }

    readonly _beneficiaryCountry: string;

    /** Description: Beneficiary country */
    get beneficiaryCountry(): string {
        return this._beneficiaryCountry;
    }

    readonly _beneficiaryCountryCode: string;

    /** Description: Beneficiary country code in aplha2 format */
    get beneficiaryCountryCode(): string {
        return this._beneficiaryCountryCode;
    }

    readonly _beneficiaryIban: string;

    /** Description: Beneficiary IBAN */
    get beneficiaryIban(): string {
        return this._beneficiaryIban;
    }

    readonly _beneficiaryName: string;

    /** Description: Beneficiary Name */
    get beneficiaryName(): string {
        return this._beneficiaryName;
    }

    readonly _beneficiaryPostalCode: string | undefined;

    /** Description: Beneficiary postal code */
    get beneficiaryPostalCode(): string | undefined {
        return this._beneficiaryPostalCode;
    }

    readonly _beneficiarySwift: string;

    /** Description: Beneficiary SWIFT */
    get beneficiarySwift(): string {
        return this._beneficiarySwift;
    }

    constructor(props: ICreateBankRequisitesRequestData) {
        this._alias = props.alias.trim();
        this._beneficiaryAddress = props.beneficiaryAddress.trim();
        if (typeof props.beneficiaryBankAddress === 'string') {
            this._beneficiaryBankAddress = props.beneficiaryBankAddress.trim();
        }
        if (typeof props.beneficiaryBankCity === 'string') {
            this._beneficiaryBankCity = props.beneficiaryBankCity.trim();
        }
        if (typeof props.beneficiaryBankCountry === 'string') {
            this._beneficiaryBankCountry = props.beneficiaryBankCountry.trim();
        }
        if (typeof props.beneficiaryBankCountryCode === 'string') {
            this._beneficiaryBankCountryCode = props.beneficiaryBankCountryCode.trim();
        }
        if (typeof props.beneficiaryBankName === 'string') {
            this._beneficiaryBankName = props.beneficiaryBankName.trim();
        }
        if (typeof props.beneficiaryBankPostalCode === 'string') {
            this._beneficiaryBankPostalCode = props.beneficiaryBankPostalCode.trim();
        }
        this._beneficiaryCity = props.beneficiaryCity.trim();
        this._beneficiaryCountry = props.beneficiaryCountry.trim();
        this._beneficiaryCountryCode = props.beneficiaryCountryCode.trim();
        this._beneficiaryIban = props.beneficiaryIban.trim();
        this._beneficiaryName = props.beneficiaryName.trim();
        if (typeof props.beneficiaryPostalCode === 'string') {
            this._beneficiaryPostalCode = props.beneficiaryPostalCode.trim();
        }
        this._beneficiarySwift = props.beneficiarySwift.trim();
    }

    serialize(): ICreateBankRequisitesRequestData {
        const data: ICreateBankRequisitesRequestData = {
            alias: this._alias,
            beneficiaryAddress: this._beneficiaryAddress,
            beneficiaryCity: this._beneficiaryCity,
            beneficiaryCountry: this._beneficiaryCountry,
            beneficiaryCountryCode: this._beneficiaryCountryCode,
            beneficiaryIban: this._beneficiaryIban,
            beneficiaryName: this._beneficiaryName,
            beneficiarySwift: this._beneficiarySwift,
        };
        if (typeof this._beneficiaryBankAddress !== 'undefined') {
            data.beneficiaryBankAddress = this._beneficiaryBankAddress;
        }
        if (typeof this._beneficiaryBankCity !== 'undefined') {
            data.beneficiaryBankCity = this._beneficiaryBankCity;
        }
        if (typeof this._beneficiaryBankCountry !== 'undefined') {
            data.beneficiaryBankCountry = this._beneficiaryBankCountry;
        }
        if (typeof this._beneficiaryBankCountryCode !== 'undefined') {
            data.beneficiaryBankCountryCode = this._beneficiaryBankCountryCode;
        }
        if (typeof this._beneficiaryBankName !== 'undefined') {
            data.beneficiaryBankName = this._beneficiaryBankName;
        }
        if (typeof this._beneficiaryBankPostalCode !== 'undefined') {
            data.beneficiaryBankPostalCode = this._beneficiaryBankPostalCode;
        }
        if (typeof this._beneficiaryPostalCode !== 'undefined') {
            data.beneficiaryPostalCode = this._beneficiaryPostalCode;
        }
        return data;
    }

    toJSON(): ICreateBankRequisitesRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: typeof this._alias === 'string' && !!this._alias.trim(),
            beneficiaryName: typeof this._beneficiaryName === 'string' && !!this._beneficiaryName.trim(),
            beneficiaryIban: typeof this._beneficiaryIban === 'string' && !!this._beneficiaryIban.trim(),
            beneficiarySwift: typeof this._beneficiarySwift === 'string' && !!this._beneficiarySwift.trim(),
            beneficiaryCountry: typeof this._beneficiaryCountry === 'string' && !!this._beneficiaryCountry.trim(),
            beneficiaryCountryCode: typeof this._beneficiaryCountryCode === 'string' && !!this._beneficiaryCountryCode.trim(),
            beneficiaryCity: typeof this._beneficiaryCity === 'string' && !!this._beneficiaryCity.trim(),
            beneficiaryAddress: typeof this._beneficiaryAddress === 'string' && !!this._beneficiaryAddress.trim(),
            beneficiaryPostalCode: !this._beneficiaryPostalCode ? true : typeof this._beneficiaryPostalCode === 'string',
            beneficiaryBankName: !this._beneficiaryBankName ? true : typeof this._beneficiaryBankName === 'string',
            beneficiaryBankCountry: !this._beneficiaryBankCountry ? true : typeof this._beneficiaryBankCountry === 'string',
            beneficiaryBankCity: !this._beneficiaryBankCity ? true : typeof this._beneficiaryBankCity === 'string',
            beneficiaryBankAddress: !this._beneficiaryBankAddress ? true : typeof this._beneficiaryBankAddress === 'string',
            beneficiaryBankPostalCode: !this._beneficiaryBankPostalCode ? true : typeof this._beneficiaryBankPostalCode === 'string',
            beneficiaryBankCountryCode: !this._beneficiaryBankCountryCode ? true : typeof this._beneficiaryBankCountryCode === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
